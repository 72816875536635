
import * as __star__ from '@clayui/tooltip';

const {
default: __default__,
ClayTooltipProvider,
__esModule
} = __star__;

export {
ClayTooltipProvider,
__esModule
};
export default __default__;
